exports.onRouteUpdate = function() {
  if (typeof window.evag === 'function') {
    const sendPageView = () => {
      window.evag('track', 'pageview');
    };

    if ('requestAnimationFrame' in window) {
      requestAnimationFrame(() => {
        requestAnimationFrame(sendPageView);
      });
    } else {
      setTimeout(sendPageView, 32);
    }
  }
};
